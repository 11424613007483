<template>
    <div class="page">
        <tinymce :height="height" v-if="height" v-model="content"/>

        <div class="bottom van-hairline--top" ref="bottom">
            <div class="left" @click="$router.push('/shelves/setUniform')">
                <img src="@/assets/images/shelves/icon.png" alt="">
                <span>设置包单品</span>
            </div>
           
            <div class="right">
                <van-button plain type="info" size="small" round @click="toAudit">
                    <span style="color: #212121">上架审核</span>
                </van-button>
                <van-button plain type="primary"  size="small" round @click="shelves">直接上架</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import Tinymce from '@/components/tinymce.vue';

export default {
    name: "ShelvesDetail",
    components: {
        Tinymce
    },

    data() {
        return {
            content: '<p style="color: rgb(186, 55, 42);">超级好看的颜色</p><img src="https://img.alicdn.com/imgextra/i4/2212344666942/O1CN01NnGnah219UEpFmtEO_!!2212344666942.jpg"/>',
            height: 0,

            form: {}
        }
    },
    methods: {
        shelves() {
            this.$toast.success('上架成功')
        },
        toAudit() {
            this.$toast.success('送审成功')
        },
    },
    mounted() {
        let height = document.documentElement.clientHeight;
        let bottom = this.$refs.bottom.clientHeight;
        this.height = height - bottom



        let data = localStorage.getItem('ShelvesDetail')
        if (data) {
            this.form = JSON.parse(data)
        }
    }
}

</script>
<style lang="less" scoped>
.van-button--small { padding: 0 15px; height: 30px; line-height: 30px; margin-left: 12px;}
.bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 11px 12px 29px;
    position: fixed;
    bottom: 0;
    width: 100%;
    .left {
        display: flex;
        align-items: center;
        flex-direction: column;
        img {
            height: 17px;
            width: 17px;
            margin-bottom: 5px;
        }

        span {
            font-size: 12px;
            line-height: 12px;
            font-weight: 400;
            color: #212121;
            display: block;
        }
    }
}
</style>